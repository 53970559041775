
import { defineAsyncComponent } from "vue";
import { Options, Vue } from "vue-class-component";
import { ProductController } from "@/app/ui/controllers/ProductController";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import formatPriceIDR from "@/app/infrastructures/misc/common-library/FormatPriceIDR";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Constants";
import { TariffController } from "@/app/ui/controllers/TariffController";
import {
  SHIPMENT_CEK_TARIF,
  SHIPMENT_STT_BOOKING
} from "@/app/infrastructures/misc/RolePermission";
import { TariffData, DetailDiscount } from "@/domain/entities/Tariff";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { QueryParamsEntities2 } from "@/domain/entities/MainApp";
import router from "@/app/ui/router";
import { CheckTariffRequest } from "@/data/payload/api/TariffApiRequest";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";
import debounce from "lodash/debounce";
import { FlagsPermissionBooking } from "@/feature-flags/flag-booking";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";
import { flags } from "@/feature-flags";
import formatPrice from "@/app/infrastructures/misc/common-library/FormatPrice";
const Detail = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/shipment/rates-check/component/detail.vue")
});
const CompareDownload = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/shipment/rates-check/component/compare.vue")
});
const EmbargoWarning = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/shipment/rates-check/component/embargo-warning.vue")
});
const OtopackWarning = defineAsyncComponent({
  loader: () =>
    import("@/app/ui/views/shipment/rates-check/component/otopack-warning.vue")
});

@Options({
  components: {
    CompareDownload,
    Detail,
    EmbargoWarning,
    OtopackWarning
  },

  computed: {
    isBookingAble() {
      return FlagsPermissionBooking.permission_booking_create_manual.isEnabled();
    }
  }
})
export default class RatesCheck extends Vue {
  // permission
  get isDetailAble() {
    if (this.isSenderAccount) {
      return true;
    }
    return checkRolePermission(SHIPMENT_CEK_TARIF.DETAIL);
  }
  get isBookingCreate() {
    if (this.isSenderAccount) {
      return false;
    }
    return checkRolePermission(SHIPMENT_CEK_TARIF.BOOKING);
  }

  get isSenderAccount() {
    return IS_SENDER_ACCOUNT;
  }

  get isAccountCountryOutsideID() {
    if (AccountController.accountData.isInternalAccount) {
      return false;
    }
    return AccountController.accountData.account_type_detail.countryCode.toLowerCase() !== 'id';
  }

  // handle toggle filter
  isShowFilter = true;
  setIsShowFilter(val: boolean) {
    this.isShowFilter = val;
  }

  // handling
  get isLoading() {
    return TariffController.isLoading;
  }
  get isError() {
    return TariffController.isError;
  }
  get errorCause() {
    return TariffController.errorCause;
  }
  get isOpenDetail() {
    return TariffController.isOpenDetail;
  }
  onClickRow(item: TariffData) {
    TariffController.setOpenDetail(true);
    TariffController.setLoadingDetail(true);
    setTimeout(() => {
      TariffController.setLoadingDetail(false);
      TariffController.setDetailTarif(item);
    }, 500);
  }
  onCloseDetail() {
    TariffController.setOpenDetail(false);
  }

  pagination = {
    page: 1,
    limit: 10
  };
  get isEmpty() {
    return this.listData.length === 0 && !this.isLoading && !this.isFilter;
  }

  get listData() {
    const retailTariffData = TariffController.retailTariffData.data;
    const tariffData = TariffController.tariffData.data;
    return [...retailTariffData, ...tariffData];
  }

  get isAnyEmbargo() {
    const checkEmbargo = TariffController.tariffData.data.filter(
      (item: TariffData) => item.isEmbargo
    );
    return {
      isAny: checkEmbargo.length > 0,
      full: checkEmbargo.length === TariffController.tariffData.data.length
    };
  }

  get isAnyOtopack() {
    const checkProduct = TariffController.tariffData.data.filter(
      (item: TariffData) => item.productType.includes("OTO")
    );
    return {
      isAny: checkProduct.length > 0,
      full: this.productName.includes("OTO")
    };
  }

  get isModalSuccessDownload() {
    return TariffController.modalSuccessDownload;
  }

  setModalDownload(boolean: boolean) {
    TariffController.setModalSuccessDownload(boolean);
  }

  get columns() {
    const column = [
    {
      name: this.isAccountCountryOutsideID ? "Service Type" : "Produk",
      styleHead: "w-40 text-left",
      render: (item: any) => {
        return `<span class='text-left text-red-lp-200'>${item.productType}</span>`;
      },
      toolTip: (item: any) => {
        if (item.productType.includes("OTO")) {
          return `<div class='flex text-black-lp-300 text-12px max-w-xs'>Tidak termasuk biaya asuransi</div>`;
        }
      },
      isEmbargo: (item: any) => {
        return item.productType.includes("OTO");
      }
    },
    {
      name: this.isAccountCountryOutsideID ?  "Commodity" : "Komoditas",
      styleHead: "w-56 text-left",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300 flex">
        ${
          item.commodityId
            ? ellipsisString( this.isAccountCountryOutsideID || this.isInternalOutsideID ? item.commodityNameEn : item.commodityName, 20)
            : "All Commodity"
        }
        </span>`;
      },
      toolTip: (item: any) => {
        if (item.isEmbargo) {
          return `<div class='flex text-black-lp-300 text-12px max-w-xs'>Embargo</div>`;
        }
      },
      isEmbargo: (item: any) => {
        return item.isEmbargo;
      }
    },
    {
      name: this.isAccountCountryOutsideID ? "Client/Partner Name" :"Nama Client/Partner",
      styleHead: "w-40 text-left",
      render: (item: any) => {
        return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      <span>
                        ${
                          item.accountRefId
                            ? ellipsisString(item.accountRefName, 20)
                            : "Retail"
                        }
                      </span>
                  </div>
                </div>`;
      }
    },
    {
      name: "Base Rate",
      styleHead: "w-40 text-left",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${this.formatPriceShipmentRates(
          item.baseRate
        )}</span>`;
      }
    },
    {
      name: "Commodity Rate",
      styleHead: "w-40 text-left",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${this.formatPriceShipmentRates(
          item.commodityRate
        )}</span>`;
      }
    },
    {
      name: "Heavyweight Surcharge",
      styleHead: "w-40 text-left",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${this.formatPriceShipmentRates(
          item.heavyWeightSurcharge
        )}</span>`;
      }
    },
    {
      name: "Document Surcharge",
      styleHead: "w-40 text-left",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${this.formatPriceShipmentRates(
          item.documentSurcharge
        )}</span>`;
      }
    },
    {
      name: this.isAccountCountryOutsideID ? "Shipment Time" : "Estimasi SLA Level Kota",
      styleHead: "w-52 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${this.isAccountCountryOutsideID ? item.sla.replace('Hari', 'Working days') : item.sla}</span>`;
      },
      tooltipHeader: () => {
        return `<div class='flex text-black-lp-300 text-center text-12px font-montserrat'>${this.$t('shipmentRates.tooltipSLA')}</div>`;
      }
    },
    {
      name: "COD",
      styleHead: "w-32 text-left",
      render: (item: TariffData) => {
        return `<span class="whitespace-nowrap text-black-lp-300">${item.isCod ? this.$t('shipmentRates.codYes') : this.$t('shipmentRates.codNo')}</span>`;
      }
    },
    {
      name: this.isAccountCountryOutsideID ? "DFOD Fee" : "Biaya DFOD",
      styleHead: "w-40 text-left",
      render: (item: TariffData) => {
        return `<span class="whitespace-nowrap text-black-lp-300">${this.formatPriceShipmentRates(
          item.dfodFee
        )}</span>`;
      }
    },
    {
      name: this.isAccountCountryOutsideID ? "COD Fee" : "Biaya COD",
      styleHead: "w-40 text-left",
      render: (item: TariffData) => {
        return `<span class="whitespace-nowrap text-black-lp-300">${this.formatPriceShipmentRates(
          item.codFee
        )}</span>`;
      }
    },
    {
      name: this.isAccountCountryOutsideID ? "COD Return Fee" : "Biaya Retur COD",
      styleHead: "w-52 text-left whitespace-nowrap",
      render: (item: TariffData) => {
        return `<span class="whitespace-nowrap text-black-lp-300">${this.formatPriceShipmentRates(
          item.codReturnFee
        )}</span>`;
      },
      tooltipHeader: () => {
        return `<div class='text-black-lp-300 text-center text-12px font-montserrat'>${this.isAccountCountryOutsideID ? 'Payment upon return package' : 'Biaya akan dibayarkan ketika paket dikembalikan'}</div>`;
      }
    },
    {
      name: this.isAccountCountryOutsideID ? "Total Cost" : "Total Tarif",
      styleHead: "w-40 md:w-60 text-left",
      render: (item: any) => {
        return `
        <div class='flex flex-col'>
        ${this.formattedStyleTotalTariff(item)}
        </div>`;
      },
      promoLabel: (item: any) => {
        if (item.isPromo && !IS_SENDER_ACCOUNT) {
          return {
            status: true,
            promos: item.listDiscount.map((el: DetailDiscount) => {
              return {
                id: el.pdcId,
                type: el.pdcCategory,
                amount: `${el.pdcPromoDiscount}% off`,
                desc: `${el.pdcName} sampai dengan ${formatDateWithoutTime(el.pdcEndDate)}`
              }
            })
          }
        }
        return;
      },
      advancedStyle: (item: any) => {
        return item.isEmbargo ? "bg-pink-lp-200" : "";
      }
    },
    {
      name: "",
      key: "actionable_column",
      styleHead: "w-40 text-center",
      styleCustom: "column-max-content",
      render: (item: any) => {
        return {
          outline: true,
          iconLeft: `${item.isEmbargo ? "plus-solid" : "plus-solid-red"}`,
          title: "Booking",
          border: "bg-gray-lp-400",
          textColor: `${item.isEmbargo ? "gray-lp-400" : "red-lp-100"}`,
          color: `${item.isEmbargo ? "bg-white opacity-50" : "red-lp-100"}`,
          small: true
        };
      },
      showButton: (item: any) => {
        return this.isSenderAccount || item.productType === "JUMBOPACK" ? false : this.showButton;
      },
      advancedStyle: (item: any) => {
        return item.isEmbargo ? "bg-pink-lp-200" : "";
      }
    }
    ];

    if (!IS_SENDER_ACCOUNT) {
      column.splice(3, 4);
    }
    return column;
  }

  formattedStyleTotalTariff(item: any) {
    const totalTariffDiscount = `<span class='text-left text-red-lp-200 font-normal'>${this.formatPriceShipmentRates(
      item.totalTariffAfterDiscount
    )}</span>`;
    if (item.isPromo) {
      return `<div class='flex flex-col'>
      <span class='text-left text-gray-lp-600 font-normal'><s>${this.formatPriceShipmentRates(
        item.totalTariff
      )}</s></span>
      ${totalTariffDiscount}
      </div>`;
    }
    return `<span class='text-left text-red-lp-200 font-normal'>${this.formatPriceShipmentRates(
      item.totalTariff
    )}</span>`;
  }

  formatPriceShipmentRates(price: number) {
    return `${formatPrice(price, "", this.isAccountCountryOutsideID || this.isInternalOutsideID ? "MYR": "IDR")}`
  }

  get showButton() {
    if (
      AccountController.accountData.account_location?.district_code ===
        this.dataOrigin.code &&
      AccountController.accountData.account_type === "partner" &&
      AccountController.accountData.account_type_detail.type === "pos"
    ) {
      return true;
    } else
      return (
        AccountController.accountData.account_location?.district_code ===
          this.dataOrigin.code &&
        AccountController.accountData.account_type === "client" &&
        AccountController.accountData.account_type_detail.parentId > 0
      );
  }

  // custom button
  customButton = {
    clickFunction: (item: any) => {
      if (!item.isEmbargo) {
        this.goToBook(item);
      }
    }
  };

  goToBook(item: any) {
    const commodity = {
      code: item?.commodityCode === "ALL" ? "GEN" : item?.commodityCode,
      name:
        item?.commodityName === "ALL"
          ? "GENERAL OTHERS - GENERAL LAINNYA"
          : item?.commodityName
    };

    const bookingQueryObject = {
      autofill: true,
      product: item?.productType,
      destinationCode: this.dataDestination.code,
      destinationName: this.dataDestination.name,
      destinationCityCode: this.dataDestination.cityCode,
      destinationStatus: this.dataDestination.status,
      destinationCod: this.dataDestination.isCod,
      destinationType: this.dataDestination.type,
      destinationVendor: this.dataDestination.vendorCode,
      commodityCode: commodity.code,
      commodityName: commodity.name,
      weight: this.filterData.weight,
      length: this.filterData.length,
      width: this.filterData.width,
      height: this.filterData.height
    };
    const bookingQuery = new QueryParamsEntities2(bookingQueryObject);
    router.push(`/shipment/booking/create?${bookingQuery.queryString}`);
  }

  get columnsData() {
    if (this.isBookingCreate) return this.columns;
    return this.columns.splice(0, this.columns.length - 1);
  }

  mounted() {
    if (!IS_SENDER_ACCOUNT) {
      this.fetchProduct("");
    }
    this.isFilter = false;
    TariffController.tariffData.data = [];
  }

  // disable
  get isDisabled(): boolean {
    return (
      !this.filterData.origin ||
      !this.filterData.destination ||
      !this.filterData.weight ||
      !this.filterData.length ||
      !this.filterData.width ||
      !this.filterData.height ||
      (!this.filterData.price && !IS_SENDER_ACCOUNT)
    );
  }
  isFilter = false;
  getData() {
    this.isFilter = true;
    this.filterData.cacheClient = this.filterData.client;
    const client: any = this.filterData.client;

    TariffController._onGetList(
      {
        params: new CheckTariffRequest({
          originId: this.filterData.origin,
          destinationId: this.filterData.destination,
          length: !IS_SENDER_ACCOUNT ? this.filterData.length : 0,
          height: !IS_SENDER_ACCOUNT ? this.filterData.height : 0,
          width: !IS_SENDER_ACCOUNT ? this.filterData.width : 0,
          grossWeight: this.filterData.weight,
          commodityId:
            this.filterData.commodity.toString() === "all"
              ? 0
              : this.filterData.commodity,
          productType: this.filterData.product,
          accountRefId: client.id || "",
          accountType: client.type || "",
          price: +this.filterData.price
        }), 
        cache: true
      }
    );

    this.pagination.page = 1;

    if (IS_SENDER_ACCOUNT) {
      GTMCommonEvent("sender_check_tariff_click");
    } else {
      GTMCommonEvent("check_tariff_clicked");
    }
  }

  // select-search-origin
  selectSearchOriginValue = "";
  statusSelectSearchOrigin = false;
  onSelectSearchOrigin = debounce((value: string) => {
    if (!value.length || value.length >= 3) {
      this.selectSearchOriginValue = value;
      const typeAccuracyAllowed = true;
      LocationController.getDistrictList({
        search: value,
        status: "",
        page: 1,
        limit: typeAccuracyAllowed ? 50 : 10,
        cache: true,
        excludeCountry: this.isAccountCountryOutsideID ? 'ID' : ''
      });
    }
  }, 250);
  onOpenSelectSearchOrigin() {
    this.statusSelectSearchOrigin = true;
    const value = this.selectSearchOriginValue;
    const typeAccuracyAllowed = true;
    LocationController.getDistrictList({
      search: value,
      status: "",
      page: 1,
      limit: typeAccuracyAllowed ? 50 : 10,
      cache: true,
      excludeCountry: this.isAccountCountryOutsideID ? 'ID' : ''
    });
  }
  onCloseSelectSearchOrigin() {
    this.statusSelectSearchOrigin = false;
  }
  dataOrigin: any = {};
  async onChooseSelectSearchOrigin(name: string, code: string, item: any) {
    this.dataOrigin = this.locationSearchOriginList.find(
      (item: any) => item.code === code
    );
    this.filterData.origin = code;
    this.selectSearchOriginValue = name;
    this.statusSelectSearchOrigin = false; 
    await this.getDetailDistrictData(item.id);
  }
  getDetailDistrictData(id: any) {
    return LocationController.getDetailDistrictTariff({ id });
  }
  get detailDistrict() {
    return LocationController.districtDetailTariff;
  }
  get isInternal() {
    return AccountController.accountData.isInternalAccount;
  }
  get isInternalOutsideID() {
    return this.isInternal && this.detailDistrict.countryCode.toLowerCase() !== 'id';
  }
  get locationSearchOriginList() {
    return LocationController.districtData.districtData.filter(
      key => key.status.toLowerCase() === "active"
    );
  }
  get locationSearchOriginNotFound() {
    return !LocationController.districtData.districtData.filter(
      key => key.status.toLowerCase() === "active"
    ).length;
  }

  // select-search destination
  selectSearchDestinationValue = "";
  statusSelectSearchDestination = false;
  onSelectSearchDestination = debounce((value: string) => {
    if (!value.length || value.length >= 3) {
      this.selectSearchDestinationValue = value;
      const typeAccuracyAllowed = true;
      LocationController.getDistrictList({
        search: value,
        status: "",
        page: 1,
        limit: typeAccuracyAllowed ? 50 : 10,
        cache: true,
        excludeCountry: this.isAccountCountryOutsideID ? 'ID' : ''
      });
    }
  }, 250);
  onOpenSelectSearchDestination() {
    this.statusSelectSearchDestination = true;
    const value = this.selectSearchDestinationValue;
    const typeAccuracyAllowed = true;
    LocationController.getDistrictList({
      search: value,
      status: "",
      page: 1,
      limit: typeAccuracyAllowed ? 50 : 10,
      cache: true,
      excludeCountry: this.isAccountCountryOutsideID ? 'ID' : ''
    });
  }
  onCloseSelectSearchDestination() {
    this.statusSelectSearchDestination = false;
  }
  dataDestination: any = {};
  onChooseSelectSearchDestination(name: string, code: string) {
    this.dataDestination = this.locationSearchDestinationList.find(
      (item: any) => item.code === code
    );
    this.filterData.destination = code;
    this.selectSearchDestinationValue = name;
    this.statusSelectSearchDestination = false;
  }
  get locationSearchDestinationList() {
    return LocationController.districtData.districtData.filter(key => key.status.toLowerCase() === "active");
  }
  get locationSearchDestinationNotFound() {
    return !LocationController.districtData.districtData.filter(key => key.status.toLowerCase() === "active").length;
  }

  // Product
  productName = "All Product";
  fetchProduct(value: string) {
    ProductController.getProductList({
      search: value,
      status: "active",
      page: 1,
      limit: 99,
      productCode: ""
    });
  }
  get productListData() {
    const data = [{ name: "All Product", value: "" }];
    if (ProductController.productData.data.length > 0) {
      ProductController.productData.data.forEach((e: any) =>
        data.push({
          name: e.name,
          value: e.name
        })
      );
    }
    return this.isAccountCountryOutsideID ? [{name: "INTERPACK", value: "INTERPACK"}] :  data; 
  }
  get productListDataWithoutOnePack() {
    const data = [{ name: "All Product", value: "" }];
    if (ProductController.productData.data.length > 0) {
      ProductController.productData.data.forEach((e: any) =>
        data.push({
          name: e.name,
          value: e.name
        })
      );
    }
    return this.isAccountCountryOutsideID ? [{name: "INTERPACK", value: "INTERPACK"}] : data.filter(
      (e: any) => e.name !== "ONEPACK"
    );
  }

  get isHiddenOnepack() {
    return flags.feature_show_product_onepack.isEnabled() && AccountController.accountData.account_type_detail.type === "pos";
  }
  productSelect = false;
  onOpenProductSelect(value: boolean) {
    this.productSelect = value;
  }
  onSelectProduct(name: string, value: string) {
    this.productName = name;
    this.filterData.product = value;
    this.onOpenProductSelect(false);
  }

  get commodityGeneralOthersId() {
    return FlagsMedusa.config_commodity_general_others_id.getValue();
  }

  // Misc Variable
  filterData = {
    origin: "",
    destination: "",
    product: "",
    client: "",
    cacheClient: "",
    commodity: this.commodityGeneralOthersId,
    weight: 1,
    length: 30,
    width: 20,
    height: 10,
    price: ""
  };

  isShowcompareTariff = false;
  onCompare() {
    this.isShowcompareTariff = true;
  }

  // Not Available
  get isNotAvailable(): boolean {
    return TariffController.isNotAvailable;
  }
  get isNotFound(): boolean {
    return TariffController.isNotFound;
  }
  get errorMessage(): string {
    return TariffController.errorMessage;
  }
  onNotAvailable(value: boolean) {
    TariffController.setNotAvailable(value);
  }
}
